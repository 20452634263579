jQuery(document).ready(function($){

    function resize_tiles() {
        $(".shape-square").each(function() {
            $(this).css("height", "auto");
            var width = $(this).width();
            $(this).height(width);
        });
    }

    resize_tiles();

    $(window).resize(resize_tiles);

});
