$(".home8__item__description__long__toggle").on("click", function() {
    const $btn = $(this);

    $(this).prev().slideToggle(function() {
        
        if ($(this).is(":visible")) {
            $btn.text("Pokaż mniej");
        } else {
            $btn.text("Pokaż więcej");
        }
    });
})