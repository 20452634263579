jQuery(document).ready(function($){

    $('.mask-nip').inputmask('999-999-99-99', {
    });

    $('.mask-postcode').inputmask('99-999', {
    });

    $('.mask-phone').inputmask({
        regex: "[\\d +-]*",
    });

    $('.mask-date').inputmask({
        alias: 'datetime',
        inputFormat: 'dd.mm.yyyy',
    });

});
