jQuery(document).ready(function ($) {

    function tense_equalize_slides($slides) {
        if (Foundation.MediaQuery.atLeast('medium')) {
            var max_height = 0;
            $slides.each(function () {
                max_height = Math.max($(this).height(), max_height);
            });
            $slides.height(max_height);
        } else {
            $slides.css('height', 'auto');
        }
    }

    $('[data-slider-equalize]').on('init reInit', function (event, slick) {
        tense_equalize_slides(slick.$slides);
    });

    function tense_resize_handler() {
        $tense_sliders.each(function () {
            var slick = $(this).slick('getSlick');
            tense_equalize_slides(slick.$slides);
        });
    }

    var $tense_sliders = $('[data-slider-equalize]');

    if ($tense_sliders.length) {
        var resize_timer;

        $(window).resize(function () {
            clearTimeout(resize_timer);

            resize_timer = setTimeout(tense_resize_handler, 200);
        });
    }

    $('.home-slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: true,
        cssEase: 'ease-out',
        arrows: true,
        fade: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    });

    $('.home-products__slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        fade: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 640,
                //settings: "unslick",
                dots: true,
            },
        ],
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });


});
